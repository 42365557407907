import { HEADER_MOBILE_HEIGHT, HEADER_DESKTOP_HEIGHT } from 'src/config';
import { styled } from '@mui/material/styles';
import { Stack, Container, Typography } from '@mui/material';
import { Image } from 'src/components';
import ServicesForm from 'src/components/forms/ServicesForm';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  minHeight: `calc(100vh - ${HEADER_MOBILE_HEIGHT}px)`,

  [theme.breakpoints.up('md')]: {
    minHeight: `calc(100vh - ${HEADER_DESKTOP_HEIGHT}px)`,
  },
}));

// ----------------------------------------------------------------------

export default function HomeServicesHero() {
  return (
    <RootStyle>
      <Container
        sx={{
          position: 'relative',
          zIndex: 9,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          pb: `${HEADER_DESKTOP_HEIGHT / 2}px`,
        }}
      >
        <Stack
          spacing={3}
          sx={{
            mb: 5,
            mx: 'auto',
            maxWidth: 480,
            textAlign: 'center',
            color: 'common.white',
          }}
        >
          <Typography variant="h1">Find world's best Freelancers</Typography>
          <Typography sx={{ opacity: 0.72 }}>
            Hire woldclass freelancers for your project
          </Typography>
        </Stack>
        <ServicesForm />
      </Container>

      <Image
        alt="hero"
        src="https://zone-assets-api.vercel.app/assets/images/marketing/marketing_services_hero.jpg"
        sx={{ position: 'absolute', top: 0, left: 0, width: 1, height: 1 }}
      />
    </RootStyle>
  );
}

// const InputStyle = styled((props) => <FilledInput fullWidth {...props} />)(({ theme }) => ({
//   color: theme.palette.common.white,
//   '& .MuiFilledInput-input': {
//     ...theme.typography.body2,
//     paddingTop: 18,
//     paddingBottom: 18,
//     '&:placeholder': {
//       color: theme.palette.grey[500],
//     },
//   },
//   [theme.breakpoints.up('md')]: {
//     backgroundColor: 'transparent',
//     '& .MuiFilledInput-input': {
//       paddingTop: 14,
//       paddingBottom: 14,
//     },
//     '&.Mui-focused, &:hover': {
//       backgroundColor: 'transparent',
//     },
//   },
// }));
