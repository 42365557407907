
// layouts
import Layout from '../src/layouts';
// components
import { Page } from '../src/components';

import { Home } from 'src/sections/home';
import useRole from 'src/hooks/useRole';
import { StoreConst } from 'src/store/local';
import Dashboard from './dashboard';

// ----------------------------------------------------------------------

const Index = () => {
  const user = useRole(StoreConst.CLIENT);

  // return <Page title="Home">{user ? <Dashboard /> : <Home />}</Page>;
  return (
    <Page title="Home">
      <Dashboard />
    </Page>
  );
};

export default Index;

// ----------------------------------------------------------------------

Index.getLayout = function getLayout(page) {
  return (
    <Layout sx={{ flex: 1 }} simpleFooter>
      {page}
    </Layout>
  );
};
